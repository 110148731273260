<template>
  <el-dialog
    :close-on-click-modal="false"
    title="福袋"
    class="dialogBlessingBag"
    :visible.sync="visible"
    :before-close="close"
    append-to-body
  >
    <el-tabs v-model="activeName" type="card" class="tabs">
      <el-tab-pane label="现金" name="formLabelAlignM">
        <el-form
          label-position="right"
          label-width="120px"
          :model="formLabelAlignM"
          ref="mRuleFormRef"
          class="blessingBagList"
          :rules="mRules"
        >
        <el-form-item label="是否随机" prop="isRandom" class="blessingBagItem">
            <el-radio v-model="formLabelAlignM.isRandom" label="1">是</el-radio>
            <el-radio v-model="formLabelAlignM.isRandom" label="0">否</el-radio>
          </el-form-item>
          <el-form-item
            label= "人均可得现金"
            prop="money"
            class="blessingBagItem"
            v-if="formLabelAlignM.isRandom ==='0'"
          >
          <!-- v-if="formLabelAlignM.isRandom ==='0'" -->

            <!-- <span>人均可得</span> -->
            <el-input
              v-model.number="formLabelAlignM.money"
              placeholder="请输入现金数"
            >
              <template #append>RMB</template>
            </el-input>
          </el-form-item>
          <el-form-item
            label= "总金额"
            prop="totalMoney"
            class="blessingBagItem"
            v-if="formLabelAlignM.isRandom ==='1'"
          >
            <!-- <span>人均可得</span> -->
            <el-input
              v-model.number="formLabelAlignM.totalMoney"
              placeholder="请输入现金数"
            >
              <template #append>RMB</template>
            </el-input>
          </el-form-item>
          <el-form-item label="中奖人数" prop="people" class="blessingBagItem">
            <el-input
              v-model.number="formLabelAlignM.people"
              placeholder="请输入中奖人数"
            >
              <template #append>人</template>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="总金额" prop="totalMoney" class="blessingBagItem">
            <div
            style="margin-left: 10px;"
            >
            {{ formLabelAlignM.people * formLabelAlignM.money + " RMB"}} 
          </div>
          </el-form-item> -->
         
        
          <el-form-item label="参与方式" prop="way" class="blessingBagItem">
            <el-input v-model="formLabelAlignM.way" disabled> </el-input>
          </el-form-item>
          <el-form-item label="口令" prop="password" class="blessingBagItem">
            <el-input
              v-model="formLabelAlignM.password"
              placeholder="请输入口令"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="开奖时间"
            prop="countdown"
            class="blessingBagItem"
          >
            <el-time-picker
              v-model="formLabelAlignM.countdown"
              placeholder="请选择开奖时间"
            />
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="实物" name="formLabelAlign">
        <el-form
          label-position="right"
          label-width="100px"
          :model="formLabelAlign"
          ref="ruleFormRef"
          class="blessingBagList"
          :rules="rules"
        >
          <el-form-item label="人均可得" prop="thing" class="blessingBagItem">
            <el-input
              v-model="formLabelAlign.thing"
              placeholder="请输入要发放的实物"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="中奖人数" prop="people" class="blessingBagItem">
            <el-input
              v-model.number="formLabelAlign.people"
              placeholder="请输入中奖人数"
            >
              <template #append>人</template>
            </el-input>
          </el-form-item>
          <el-form-item label="参与方式" prop="way" class="blessingBagItem">
            <el-input v-model="formLabelAlign.way" disabled> </el-input>
          </el-form-item>
          <el-form-item label="口令" prop="password" class="blessingBagItem">
            <el-input
              v-model="formLabelAlign.password"
              placeholder="请输入口令"
            >
            </el-input>
          </el-form-item>
          <el-form-item
            label="开奖时间"
            prop="countdown"
            class="blessingBagItem"
          >
            <el-time-picker
              v-model="formLabelAlign.countdown"
              placeholder="请选择开奖时间"
            />
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

    <template #footer>
      <span class="dialog-footer">
        <el-button type="danger" @click="giveOutLuckBag">
          发起福袋
          {{
            `(${
              activeName === "formLabelAlignM"
                ?  formLabelAlignM.isRandom ==='1' ? formLabelAlignM.totalMoney + "现金" : formLabelAlignM.people * formLabelAlignM.money + "现金"
                : Number(formLabelAlign.people) + "个" + formLabelAlign.thing
            })`
          }}
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { createRaffle } from "@/api";
export default {
  props: {
    // visible: Boolean,
    liveData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      money: "",
      activeName: "formLabelAlignM",
      visible: true,
      formLabelAlign: {
        thing: "",
        people: null,
        way: "口令参与",
        password: "",
        countdown: null,
      },
      formLabelAlignM: {
        money: null,
        people: null,
        way: "口令参与",
        password: "",
        countdown: null,
        isRandom:"0",
        totalMoney:null,
      },
      rules: {
        thing: [
          { required: true, message: "请输入要发放的实物" },
          { validator: this.validateThing },
          {
            min: 1,
            max: 10,
            message: "长度在 1 到 10 个字符",
          },
        ],
        people: [
          { required: true, message: "请输入中奖人数" },
          { type: "number", message: "请输入数字" },
          { validator: this.validatePeople },
        ],
        way: [],
        password: [
          { required: true, message: "请输入口令" },
          { validator: this.validatePassword },
        ],
        countdown: [
          { required: true, message: "请输入倒计时" },
          { validator: this.validateCountdown },
        ],
      },
      mRules: {
        money: [
          { required: true, message: "请输入现金数" },
          { type: "number", message: "请输入数字" },
          { validator: this.validateMoney },
        ],
        totalMoney:[
          { required: true, message: "请输入现金数" },
          { type: "number", message: "请输入数字" },
          { validator: this.validateMoney },
        ],
        people: [
          { required: true, message: "请输入中奖人数" },
          { type: "number", message: "请输入数字" },
          { validator: this.validatemPeople },
        ],
        way: [],
        password: [
          { required: true, message: "请输入口令" },
          { validator: this.validatemPassword },
        ],
        countdown: [
          { required: true, message: "请输入倒计时" },
          { validator: this.validatemCountdown },
        ],
        isRandom: [
          { required: true, message: "" },
         
        ],
      },
    };
  },
  methods: {
   
    validateMoney(rule, value, callback) {
      console.log(value, "value1111");
      if (Number(value) <= 0) {
        callback(new Error("请输入大于0的数"));
      } else {
        callback();
      }
    },
    validatemPeople(rule, value, callback) {
      console.log(value, "value1111");
      if (Number(value) <= 0) {
        callback(new Error("请输入大于0的数"));
      } else {
        callback();
      }
    },
    validatePeople(rule, value, callback) {
      if (Number(value) <= 0) {
        callback(new Error("请输入大于0的数"));
      } else {
        callback();
      }
    },
    validateThing(rule, value, callback) {
      if (value.trim() === "") {
        callback(new Error("不能为空"));
      } else {
        callback();
      }
    },
    validatemPassword(rule, value, callback) {
      const stringTest = /\s/;
      if (stringTest.test(value)) {
        callback(new Error("不能输入空格"));
      } else {
        callback();
      }
    },
    validatePassword(rule, value, callback) {
      const stringTest = /\s/;
      if (stringTest.test(value)) {
        callback(new Error("不能输入空格"));
      } else {
        callback();
      }
    },
    validateCountdown(rule, value, callback) {
      const getData = new Date();
      const currentTime = getData.getTime();
      const dateValue = new Date(value);
      const finishTime = dateValue.getTime();
      if (finishTime < currentTime) {
        callback(new Error("不能小于当前时间"));
      } else {
        callback();
      }
    },
    validatemCountdown(rule, value, callback) {
      const getData = new Date();
      const currentTime = getData.getTime();
      const dateValue = new Date(value);
      const finishTime = dateValue.getTime();
      if (finishTime < currentTime) {
        callback(new Error("不能小于当前时间"));
      } else {
        callback();
      }
    },

    close() {
      this.$emit("closeDialog", true);
    },
    elTabClass(tabName) {
      return this.activeName === tabName ? "activeTab" : "defaultTab";
    },

    async giveOutLuckBag() {
      const formName =
        this.activeName === "formLabelAlignM" ? "mRuleFormRef" : "ruleFormRef";
      const formValue =
        this.activeName === "formLabelAlignM"
          ? this.formLabelAlignM
          : this.formLabelAlign;
      // if (!formName.value) return;
      await this.$refs[formName].validate(async (valid, fields) => {
        if (valid) {
          const awardInfo =
            this.activeName === "formLabelAlignM" ? (this.formLabelAlignM.isRandom ==='1' ?  String(this.formLabelAlignM.totalMoney) : String( this.formLabelAlignM.money )) :String(this.formLabelAlign.thing);
          const awardType = this.activeName === "formLabelAlignM" ? 1 : 0;
          const crashAward =
            this.activeName === "formLabelAlignM"
              ? this.formLabelAlignM.money || undefined
              : undefined;
          const totalCrashAward = this.activeName === "formLabelAlignM"
              ? this.formLabelAlignM.totalMoney || undefined
              : undefined;
          const getData = new Date(formValue.countdown);
          const finishTime = getData.getTime();
          // const createData = JSON.parse(
          //   window.sessionStorage.getItem("createData")
          // );
          console.log(this.liveData, "this.liveData");
          const params = {
            awardInfo,
            awardNum: formValue.people,
            awardType,
            crashAward,
            totalCrashAward,
            finishTime,
            participationCode: formValue.password,
            roomId: this.liveData.id,
            randomStatus: Number(formValue.isRandom)
          };
          const res = await createRaffle(params);
          if (res.data.code === 200) {
            this.$message.success("发起福袋成功");
            // ElMessage({
            //   message: "发起福袋成功",
            //   type: "success",
            // });
            this.$emit("closeDialog", true);
          } else {
            this.$message.error(res.data.msg);
          }
          console.log("submit!", valid, res);
        } else {
          console.log("error submit!", fields);
        }
      });
    },
  },
  // return {
  //   ...toRefs(data),
  //   close,
  //   giveOutLuckBag,
  //   ruleFormRef,
  //   mRuleFormRef,
  //   elTabClass,
  // };
  // },
};
</script>
<style>
.dialogBlessingBag {
  /* color: #fff !important; */
}
.dialogBlessingBag .el-dialog__footer {
  /* background-color: #1a1b1d !important; */
  text-align: center;
}
.dialogBlessingBag .tabs .is-top {
  /* background: #2f2f2f !important; */
  /* color: #ccc !important; */
}
.dialogBlessingBag .tabs .is-active {
  /* background: #3f3f3f; */
  /* color: white !important; */
}
.dialogBlessingBag .el-form-item__label {
  /* color: white !important; */
}
</style>
