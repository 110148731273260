<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>直播管理</el-breadcrumb-item>
      <el-breadcrumb-item>直播列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="liveListContent">
      <el-input
        placeholder="根据主播名查询"
        style="width: 200px; margin-top: 5px"
        v-model="queryInfo.userName"
        size="mini"
      >
        <!-- oninput="value=value.replace(/[^\d]/g,'')" -->

        <el-button
          @click="searchForAnchor()"
          slot="append"
          icon="el-icon-search"
        />
      </el-input>
      &nbsp;
      <el-input
        placeholder="根据直播间名字查询"
        style="width: 200px; margin-top: 5px"
        v-model="queryInfo.roomName"
        size="mini"
      >
        <el-button
          @click="searchForLive()"
          slot="append"
          icon="el-icon-search"
        />
      </el-input>
      &nbsp;

      <el-select
        v-model="status"
        clearable
        size="mini"
        placeholder="根据直播状态查询"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>

      <el-table
        ref="showTableData"
        :data="showTableData"
        border
        style="width: calc(100vw - 240px)"
      >
        <!-- <el-table-column
          :label="labelArray[index]"
          :prop="propValue(item, index)"
          :width="columnWidthArray[index]"
          v-for="(item, index) in labelArray"
          :key="index"
        >
        </el-table-column> -->
        <el-table-column label="直播间封面" prop="coverUrl" width="150px">
          <template slot-scope="scope">
            <img
              :src="scope.row.coverUrl"
              width="120px"
              height="120px"
              alt=""
            />
          </template>
        </el-table-column>
        <el-table-column label="主播头像" prop="extend" width="150">
          <template slot-scope="scope">
            <div>
              <!-- const url =  JSON.parse(item).avatar; -->
              <img
                :src="JSON.parse(scope.row.extend).userAvatar"
                width="120px"
                height="120px"
                alt=""
              />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="主播名字" prop="anchorNick" width="">
        </el-table-column>
        <el-table-column label="直播间名字" prop="title" width="">
        </el-table-column>
        <el-table-column label="创建直播间时间" prop="createTime" width="">
          <template slot-scope="scope">
            <div>
              {{ createTimeRender(scope.row.createTime) }}
              <!-- {{ scope.row.createTime }} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="状态" prop="status" width="">
          <template slot-scope="scope">
            <div>
              {{ ["未开播", "正在直播", "直播结束"][scope.row.status] }}
              <!-- {{ scope.row.createTime }} -->
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            &nbsp;&nbsp;
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              icon="el-icon-info"
              icon-color="red"
              title="确定强制下播？"
              @confirm="toStopLive(scope.row)"
            >
              <el-button type="text" slot="reference"> 强制下播 </el-button>
            </el-popconfirm>
            &nbsp;&nbsp;
            <el-button type="text" @click="toViewViolation(scope.row)">
              查看违规
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toViewDetail(scope.row)">
              查看详情
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toSendBlessingBag(scope.row)">
              发起福袋
            </el-button>
            &nbsp;&nbsp;
            <el-button type="text" @click="toViewBlessingBagDetail(scope.row)">
              福袋详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        background
        @size-change="_handleSizeChange"
        @current-change="_handleCurrentChange"
        :current-page="queryInfo.current"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableOtherInfo.total"
      >
      </el-pagination>

      <el-dialog
        title="详细信息"
        :visible.sync="dialogDetailVisible"
        width="70%"
        append-to-body
      >
        <div>总人数：{{ watchUserTotal }}</div>
        <el-table
          ref="violationRecordsList"
          :data="watchUserList"
          border
          style="width: calc(100vw - 240px)"
        >
          <el-table-column label="用户头像" prop="userHead">
            <template slot-scope="scope">
              <img
                :src="scope.row.userHead"
                width="70px"
                height="70px"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="用户名" prop="userName"> </el-table-column>
          <el-table-column label="用户id" prop="userId"> </el-table-column>

          <el-table-column label="观看时间" prop="watchTime">
            <template slot-scope="scope">
              <div v-if="scope.row.watchTime">
                {{ getWatchTime(scope.row.watchTime) }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="微信头像" prop="wxHead">
            <template slot-scope="scope">
              <img :src="scope.row.wxHead" width="70px" height="70px" alt="" />
            </template>
          </el-table-column>
          <el-table-column label="微信名" prop="wxNick"> </el-table-column>
          <el-table-column label="备注" prop="remark">
            <template slot-scope="scope">
              <div v-if="scope.row.remark">
                {{ scope.row.remark }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="toUpdateRemark(scope.row)">
                修改备注
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogDetailVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogDetailVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="福袋详情"
        :visible.sync="blessingBagVisible"
        width="70%"
        append-to-body
      >
        <el-table
          :data="blessingBagList"
          border
          style="width: calc(100vw - 240px)"
        >
          <el-table-column label="用户头像" prop="userHead">
            <template slot-scope="scope">
              <img
                :src="scope.row.userHead"
                width="70px"
                height="70px"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="用户名" prop="userName"> </el-table-column>
          <el-table-column label="用户id" prop="userId"> </el-table-column>

          <el-table-column label="抽奖活动id" prop="raffleId">
            <!-- <template slot-scope="scope">
              <div v-if="scope.row.watchTime">
                {{ getWatchTime(scope.row.watchTime) }}
              </div>
              <div v-else>-</div>
            </template> -->
          </el-table-column>
          <el-table-column label="手机号" prop="mobile">
            <!-- <template slot-scope="scope">
              <img :src="scope.row.wxHead" width="70px" height="70px" alt="" />
            </template> -->
          </el-table-column>
          <el-table-column label="中奖金额" prop="crashAward">
            <template slot-scope="scope">
              <div v-if="scope.row.crashAward">
                {{ scope.row.crashAward }}元
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column>
          <el-table-column label="礼物信息" prop="awardInfo">
            <!-- <template slot-scope="scope">
              <img :src="scope.row.wxHead" width="70px" height="70px" alt="" />
            </template> -->
          </el-table-column>
          <!-- <el-table-column label="备注" prop="remark">
            <template slot-scope="scope">
              <div v-if="scope.row.remark">
                {{ scope.row.remark }}
              </div>
              <div v-else>-</div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="toUpdateRemark(scope.row)">
                修改备注
              </el-button>
            </template>
          </el-table-column> -->
        </el-table>

        <span slot="footer" class="dialog-footer">
          <!-- <el-button @click="blessingBagVisible = false">取 消</el-button> -->
          <el-button type="primary" @click="blessingBagVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <el-dialog
        title="违规记录"
        :visible.sync="dialogVisible"
        width="70%"
        append-to-body
      >
        <!-- <div>
          <span>违规文字</span>
          <p>{{}}</p>
        </div>
        <div>
          <span>违规视频</span>
          <a href=""></a>
        </div>
        <div>
          <span>违规图片</span>
          <a href=""></a>
        </div>
        <div>
          <span>违规语音</span>
          <a href=""></a>
        </div> -->

        <el-table
          ref="violationRecordsList"
          :data="violationRecordsList"
          border
          style="width: calc(100vw - 240px)"
        >
          <el-table-column label="违规文字" prop="texts">
            <template slot-scope="scope">
              <div v-if="scope.row.texts">
                <p>{{ scope.row.texts }}</p>
              </div>
              <div v-else>暂无</div>
            </template>
          </el-table-column>
          <el-table-column label="违规图片" prop="evidenceUrl">
            <template slot-scope="scope">
              <div v-if="scope.row.type === '图片'">
                <img
                  :src="scope.row.evidenceUrl"
                  width="70px"
                  height="70px"
                  alt=""
                />
              </div>
              <div v-else>暂无</div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="违规语音" prop="evidenceUrl">
            <template slot-scope="scope">
              <div v-if="scope.row.type === '语音'">
                <audio controls>
                  <source :src="scope.row.evidenceUrl" type="audio/wav" />
                  您的浏览器不支持audio元素。
                </audio>
              </div>
              <div v-else>暂无</div>
            </template>
          </el-table-column> -->
        </el-table>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <PushBlessingBag
        v-if="dialogBlessingBagVisible"
        :liveData="liveData"
        @closeDialog="closeDialog"
      >
      </PushBlessingBag>
    </div>
  </div>
</template>
<script>
import PushBlessingBag from "./pushBlessingBag.vue";
import {
  stopPlay,
  searchRoomInfoList,
  forceStopRoom,
  getViolationRecords,
  getWatchUserList,
  updateRemark,
} from "@/api";
import { Message } from "element-ui";
import { formatTime } from "../../utils/utils";

export default {
  components: {
    PushBlessingBag,
  },
  data() {
    return {
      liveData: {},
      watchUserTotal: 0,
      dialogBlessingBagVisible: false,
      dialogVisible: false,
      dialogDetailVisible: false,
      blessingBagVisible: false,
      blessingBagList: [],
      watchUserList: [],
      status: null,
      options: [
        {
          value: 0,
          label: "未开始",
        },
        {
          value: 1,
          label: "正在直播",
        },
        {
          value: 2,
          label: "直播结束",
        },
      ],

      queryInfo: {
        page: 1,
        size: 10,
        order: "desc",
        keyword: "",
        // status: ,
        userName: "",
        roomName: "",
        // businessId: 0,
      },
      // labelArray: ["直播间封面", "主播头像", "标题", "创建直播间时间"],
      // propName: ["coverUrl", "extend", "title", "createTime"],
      columnWidthArray: [100, 150, "", 100, ""],
      myLiveInfo: { user_id: window.sessionStorage.getItem("userId") },

      tableData: [
        {
          name: "安阳扁粉菜",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜2",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜3",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜4",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
        {
          name: "安阳扁粉菜5",
          price: "0.9",
          salesVolume: "20",
          inventory: "9900",
        },
      ],
      tableOtherInfo: { total: 0, opera: "强制下播" },
      // shopTotal: 0,
      showTableData: [],
      multipleSelection: [],
      dialogMultipleSelection: [],
      playTableData: [],
      violationRecordsList: [],
    };
  },
  watch: {
    status: {
      handler(newVal, oldVal) {
        console.log(newVal, "newValnewValnewValnewVal");
        this.queryInfo.status = newVal;
        this.getLiveList();
      },
      immediate: false,
    },
  },
  methods: {
    closeDialog() {
      this.dialogBlessingBagVisible = false;
    },
    toSendBlessingBag(item) {
      console.log(item, "item");
      this.dialogBlessingBagVisible = true;
      this.liveData = item;
    },
    async toViewBlessingBagDetail(item) {
      this.blessingBagVisible = true;
      const res = await this.$http.get(
        `/api/platform/getParticipateRaffleList?roomId=${item.id}&page=1&size=1000`
      );
      console.log(res.data.body.list, "res.data.body.list");
      this.blessingBagList = res.data.body.list;
    },
    async toViewDetail(item) {
      this.dialogDetailVisible = true;

      const res = await this.$http.get(
        `/api/platform/liveWatch/getWatchUserList?roomId=${item.id}&page=1&size=1000`
      );

      this.watchUserList = res.data.body.list;
      this.watchUserTotal = res.data.body.total;
    },
    getWatchTime(time) {
      return Math.floor(time / 60) + "分";
      // formatTime(time);
      // 使用 Date 对象的方法获取年、月、日等信息
    },
    async toUpdateRemark(item) {
      this.$prompt("请输入备注", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: item.remark,
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        // inputErrorMessage: '邮箱格式不正确'
      })
        .then(async ({ value }) => {
          const formData = new FormData();
          formData.append("id", item.id);

          formData.append("remark", value || "");
          // const params = {
          //   id: item.id,
          //   // remark: item.remark,
          // };
          const { data: res } = await updateRemark(formData);
          if (res.code === 200) {
            this.$message.success("修改备注成功");
            this.toViewDetail({ id: item.roomId });
          } else {
            this.$message.error(res.body.msg || "修改失败");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // statusChange(value) {},
    searchForAnchor() {
      this.queryInfo.page = 1;

      this.getLiveList();
    },
    searchForLive() {
      this.queryInfo.page = 1;

      this.getLiveList();
    },
    async toViewViolation(item) {
      // const params = {
      //   roomId: item.id,
      // };
      const formData = new FormData();
      formData.append("roomId", item.id);
      formData.append("page", 1);
      const { data: res } = await getViolationRecords(formData);
      console.log(res, "resresres");

      this.violationRecordsList = res.body.list;
      this.dialogVisible = true;
    },
    createTimeRender(timestamp) {
      const date = new Date(timestamp);

      // 使用 Date 对象的方法获取年、月、日等信息
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份是从0开始的，所以要加1
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();

      // 构建标准日期格式的字符串
      const formattedDate =
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds;

      return formattedDate;
    },
    async toStopLive(item) {
      console.log("xia播", item);
      const formData = new FormData();
      formData.append("roomId", item.id);
      // const params = {
      //   roomId: item.id,
      //   // userId: this.myLiveInfo.user_id,
      // };
      const res = await forceStopRoom(formData);
      this.$message.success("下播成功");
      console.log(res, "强制下播");
      this.getLiveList();
    },
    _handleSizeChange(newSize) {
      this.queryInfo.size = newSize;
      // if (this.tableData.length !== 0) {
      //   this.showTableData = this.tableData.slice(0, newSize);
      // }
      this.queryInfo.page = 1;
      this.getLiveList();

      // this.getShoppingList();
      // console.log(`每页 ${newSize} 条`);
    },

    _handleCurrentChange(data) {
      this.queryInfo.page = data;
      // if (this.tableData.length !== 0) {
      // if(this.queryInfo.size === 10 ){
      // const size = this.queryInfo.size;
      this.getLiveList();
      // this.showTableData = this.tableData.slice(
      //   (val - 1) * size,
      //   (val - 1) * size + size
      // );
      // }
      // }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    async getLiveList() {
      const formData = new FormData();
      for (const key in this.queryInfo) {
        if (Object.hasOwnProperty.call(this.queryInfo, key)) {
          formData.append(key, this.queryInfo[key]);
          console.log("111", formData, key, this.queryInfo[key]);
        }
      }
      // formData.append("key", "1");
      const params = new URLSearchParams(formData);

      const { data: res } = await this.$http.get(
        `/api/platform/searchRoomInfoList?${params.toString()}`
      );

      console.log(res, "resresresresres");
      this.tableData = res.body?.list;
      this.showTableData = res.body?.list;
      // res.body.list.slice(0, 10);
      this.tableOtherInfo.total = res.body.total;
    },
  },
  created() {
    this.getLiveList();
  },
};
</script>
<style scoped>
.liveListContent {
  background-color: #fff;
  padding: 15px 15px;
}
</style>
